<template>
  <div class="vap-page">
    <div id="header"  class="vap-page-header">
      <h1>Global Search Setup</h1>
      <div class="btns-container">
        <VasionButton
          id="save-button"
          class="save-button"
          name="save-button"
          classProp="primary"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <VasionCheckbox
        id="global-search-checkbox"
        class="flex-row"
        name="global-search-checkbox"
        :checked="isGlobalSearchEnabled"
        @change="setGlobalSearch()"
      >
        Enabled
      </VasionCheckbox>
      <div>
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedCustomizeFolder"
          :dataArray="folderSearchConfigurations"
          :isDisabled="!isGlobalSearchEnabled"
          width="100%"
          class="row"
          title="CUSTOMIZED FOLDER VIEW"
          type="plain-list"
          displayName="name"
          valueName="id"
          @input="markIsDirty()"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <AutomationEngineSharedHeader
          ref="frequencyComponent"
          :hideNameLabel="true"
          :isDisabled="!isGlobalSearchEnabled"
          :restrictHeight="false"
          useForSchedulingOnly
          class="row"
          nameLabel="''"
          @dirty="markIsDirty();"
        />
      </div>
    </div>
    <VasionSnackbar
      id="save-global-search-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
  </div>
</template>

<script>
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue'

export default {
  name: 'GlobalSearchSetup',
  components: {
    AutomationEngineSharedHeader,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  props: {
  },
  data: function () {
    return {
      isDirty: false,
      isGlobalSearchEnabled: false,
      selectedCustomizeFolder: {},
      selectedCustomizeFolderId: 0,
      showLeaveDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    folderSearchConfigurations() { return this.$store.state.search.folderSearchConfigurations },
  },
  watch: {
    folderSearchConfigurations: function () { this.selectedCustomizeFolder = this.folderSearchConfigurations.find(folder => folder.id === this.selectedCustomizeFolderId) },
  },
  async created() {
    this.load()
    await this.$store.dispatch('search/getFolderSearchConfigurations')
  },
  methods: {
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    async load() {
      const payload = await this.$store.dispatch('search/loadGlobalSearchDetails', 0)

      if (payload.isGlobalSearchEnabled) {
        this.isGlobalSearchEnabled = payload.isGlobalSearchEnabled === 'True'
      }

      if (payload.globalSearchFolderIndexSchedule) {
        const { frequencyComponent } = this.$refs
        frequencyComponent.setValues(payload.globalSearchFolderIndexSchedule)
      }

      if (payload.globalSearchCustomFolderId) {
        this.selectedCustomizeFolderId = payload.globalSearchCustomFolderId
        this.selectedCustomizeFolder = this.folderSearchConfigurations.find(folder => folder.id === this.selectedCustomizeFolderId)
      }
    },
    async markIsDirty()
    {
      this.isDirty = true;
    },
    async save() {
      const { frequencyComponent } = this.$refs
      await frequencyComponent.validate()
      if (this.isGlobalSearchEnabled && frequencyComponent.errors) {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = frequencyComponent.errors
        this.showSnackbar = true
        this.snackbarImage = false
        return
      }
      const scheduleDetails = await frequencyComponent.getValues()
      const payload = {
        isGlobalSearchEnabled: this.isGlobalSearchEnabled,
        globalSearchCustomFolderId: this.selectedCustomizeFolder?.id,
        globalSearchFolderIndexSchedule: { folderId: null, scheduleDetails },
      }

      if (!await this.$store.dispatch('search/saveGlobalSearchDetails', payload)) {
        const errorText = 'There was an error saving.'
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = errorText
        this.showSnackbar = true
        this.snackbarImage = false
        console.warn(errorText)
      } else {
        await this.$store.dispatch('search/getGlobalSearchEnabled')
        this.snackbarTitle = 'SUCCESS'
        this.snackbarSubTitle = 'Saved Successfully'
        this.showSnackbar = true
        this.snackbarImage = true
        this.isDirty = false
      }
    },
    setFrequencyDetails(id, details) {
      const { frequencyComponent } = this.$refs
      if (id > 0) {
        frequencyComponent.setValues(details)
      } else {
        frequencyComponent.setValues()
      }
    },
    setGlobalSearch() { this.isGlobalSearchEnabled = !this.isGlobalSearchEnabled; this.isDirty = true; },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .flex-row{
    display: flex;
    flex-direction: row;
  }

  .title{
    text-align: left;
    flex: 1 1;
  }

  .row{
    margin: 0px;
    margin-top: 15px;
  }

</style>
